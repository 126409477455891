import "./diagram2v2.scss"

import React from "react"

import t from "locale"

const Diagram2 = ({ lang }) => {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 336.1 260.1"
      className="diagram-version-2v2"
    >
      <rect x="25.1" y="209.6" class="st0" width="302.2" height="0.7" />
      <rect x="25.3" y="121.9" class="st1" width="302.2" height="0.7" />
      <rect x="25.3" y="180.4" class="st1" width="302.2" height="0.7" />
      <text transform="matrix(1.0134 0 0 1 7.9355 95.3623)" class="st2 st3 st4">
        80%
      </text>
      <text transform="matrix(1.0134 0 0 1 4.4668 66.4982)" class="st2 st3 st4">
        100%
      </text>
      <rect
        x="235"
        y="195.3"
        class="st5 animated-bar animated-bar--5"
        width="20"
        height="14.3"
      />
      <rect x="25.3" y="92.7" class="st1" width="302.2" height="0.7" />
      <rect x="25.3" y="63.5" class="st1" width="302.2" height="0.7" />
      <rect x="25.3" y="151.1" class="st1" width="302.2" height="0.7" />
      <rect x="24.5" y="63.5" class="st0" width="0.8" height="146.8" />
      <text
        transform="matrix(1.0134 0 0 1 7.9854 124.3623)"
        class="st2 st3 st4"
      >
        60%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 7.9712 154.8623)"
        class="st2 st3 st4"
      >
        40%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 8.1978 183.6123)"
        class="st2 st3 st4"
      >
        20%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 12.2061 210.8623)"
        class="st2 st3 st4"
      >
        0%
      </text>
      <rect
        x="102.9"
        y="115"
        class="st5 animated-bar animated-bar--2"
        width="20"
        height="94.6"
      />
      <rect
        x="58.8"
        y="93"
        class="st5 animated-bar animated-bar--1"
        width="20"
        height="116.6"
      />
      <text
        transform="matrix(1.0134 0 0 1 103.3102 107.99)"
        class="st5 st3 st6 animated-text"
      >
        65%
      </text>
      <rect
        x="146.6"
        y="151.5"
        class="st5 animated-bar animated-bar--3"
        width="20"
        height="58.2"
      />
      <text
        transform="matrix(1.0134 0 0 1 146.7325 144.4498)"
        class="st5 st3 st6 animated-text"
      >
        40%
      </text>
      <rect
        x="190.5"
        y="166.1"
        class="st5 animated-bar animated-bar--4"
        width="20"
        height="43.5"
      />
      <text
        transform="matrix(1.0134 0 0 1 190.8233 159.1219)"
        class="st5 st3 st6 animated-text"
      >
        30%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 235.8125 188.3405)"
        class="st5 st3 st6 animated-text"
      >
        10%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 282.5448 202.5997)"
        class="st5 st3 st6 animated-text"
      >
        0%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 58.926 86.05)"
        class="st5 st3 st6 animated-text"
      >
        80%
      </text>
      <text
        transform="matrix(1.0134 0 0 1 148.7708 222.3115)"
        class="st2 st3 st4"
      >
        2015
      </text>
      <text
        transform="matrix(1.0134 0 0 1 192.5281 222.3115)"
        class="st2 st3 st4"
      >
        2018
      </text>
      <text
        transform="matrix(1.0134 0 0 1 236.5986 222.3115)"
        class="st2 st3 st4"
      >
        2020
      </text>
      <text
        transform="matrix(1.0134 0 0 1 281.5335 222.3115)"
        class="st2 st3 st4"
      >
        2021
      </text>
      <text
        transform="matrix(1.0134 0 0 1 104.9848 222.3115)"
        class="st2 st3 st4"
      >
        2013
      </text>
      <text
        transform="matrix(1.0134 0 0 1 60.752 222.3115)"
        class="st2 st3 st4"
      >
        2010
      </text>
      <g>
        <text transform="matrix(1 0 0 1 66.4158 17.1031)" class="st7 st3 st6">
          {t("% of Hiddenfjord salmon transported by air", lang)}
        </text>
      </g>
    </svg>
  )
}

export default Diagram2
