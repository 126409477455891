import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BigHeader from "../components/bigHeader"
import Diagram2v2 from "../components/diagrams/diagram2v2"
import Diagram3v3 from "../components/diagrams/diagram3v3"
import { Waypoint } from "react-waypoint"
import $ from "jquery"
import { StaticQuery, graphql } from "gatsby"

const LowEmissionShipment = ({ contentField }) => {
  const diagram1 = () => {
    $(".diagrams-container--1 .animated-bar, .animated-text").addClass("active")
  }

  const diagram2 = () => {
    $(".diagrams-container--2 .animated-bar, .animated-text").addClass("active")
  }

  const lang = "en"
  const seo = contentField.seo
  const image = contentField.featuredImage
  return (
    <Layout
      hideVideo={true}
      lang={lang}
      translationDE="/de/emissionsarmer-transport/"
      translationES="/es/transporte-de-bajas-emisiones/"
      translationIT="/it/basse-emissioni-di-spedizione/"
      translationFR="/fr/transport-avec-de-faibles-emissions-carbone/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="Low-emission shipment"
        image={require("../assets/images/low-emission-back.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.LowEmissionShipmentACF.firstText,
          }}
        />
        <div className="text-container">
          <br />
          <Waypoint onEnter={diagram1} bottomOffset="35%">
            <div className="diagrams-container--1">
              <Diagram3v3 lang={lang} />
            </div>
          </Waypoint>
          <br />
        </div>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.LowEmissionShipmentACF.secondText,
          }}
        />
        <div className="text-container">
          <div className="quote-container">
            <i className="quote-container__text">
              {contentField.LowEmissionShipmentACF.quote}
            </i>
            <p className="quote-container__author">
              {contentField.LowEmissionShipmentACF.author}
            </p>
          </div>
        </div>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.LowEmissionShipmentACF.thirdText,
          }}
        />
        <div className="text-container">
          <br />
          <Waypoint onEnter={diagram2} bottomOffset="35%">
            <div className="diagrams-container--2">
              <Diagram2v2 />
            </div>
          </Waypoint>
          <br />
        </div>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.LowEmissionShipmentACF.fourthText,
          }}
        />
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoyMDg=" }) {
          LowEmissionShipmentACF {
            firstText
            secondText
            quote
            author
            thirdText
            fourthText
          }
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <LowEmissionShipment {...data} />}
  />
)
