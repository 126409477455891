import "./diagram3v3.scss"

import React from "react"

import t from "locale"

const Diagram3 = ({ lang }) => {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      x="0px"
      y="0px"
      viewBox="0 0 336.1 240.6"
      className="diagram-version-3v3"
    >
      <switch>
        <g>
          <rect x="22.1" y="190.1" class="st0" width="302.2" height="0.7" />
          <rect x="22.3" y="102.4" class="st1" width="302.2" height="0.7" />
          <rect x="22.3" y="160.9" class="st1" width="302.2" height="0.7" />
          <text
            transform="matrix(1.0134 0 0 1 9.335 75.8822)"
            class="st2 st3 st4"
          >
            20
          </text>
          <rect
            x="238.8"
            y="161.9"
            width="27.8"
            height="2"
            class="st5 animated-bar animated-bar--3"
          />
          <rect
            x="238.8"
            y="163.9"
            class="st6"
            width="27.8"
            height="26.2"
            className="st6 animated-bar animated-bar--4"
          />
          <rect x="22.3" y="73.2" class="st1" width="302.2" height="0.7" />
          <rect x="22.3" y="131.6" class="st1" width="302.2" height="0.7" />
          <rect x="21.5" y="73.2" class="st0" width="0.8" height="117.6" />
          <text
            transform="matrix(1.0134 0 0 1 10.5122 104.8822)"
            class="st2 st3 st4"
          >
            15
          </text>
          <text
            transform="matrix(1.0134 0 0 1 10.0371 135.3822)"
            class="st2 st3 st4"
          >
            10
          </text>
          <text
            transform="matrix(1.0134 0 0 1 13.8179 164.1322)"
            class="st2 st3 st4"
          >
            5
          </text>
          <text
            transform="matrix(1.0134 0 0 1 13.3428 191.3822)"
            class="st2 st3 st4"
          >
            0
          </text>
          <rect
            x="160.2"
            y="163.9"
            class="st6"
            width="27.8"
            height="26.2"
            className="st6 animated-bar animated-bar--4"
          />
          <rect
            x="160.2"
            y="122.4"
            class="st5 animated-bar animated-bar--2"
            width="27.8"
            height="41.5"
          />
          <rect
            x="82.1"
            y="96.2"
            class="st5 animated-bar animated-bar--1"
            width="27.8"
            height="67.9"
          />
          <rect
            x="82.1"
            y="163.9"
            class="st6"
            width="27.8"
            height="26.2"
            className="st6 animated-bar animated-bar--4"
          />
          <text
            transform="matrix(1.0134 0 0 1 245.409 179.9486)"
            class="st7 st3 st8 animated-text"
          >
            4.6
          </text>
          <text
            transform="matrix(1.0134 0 0 1 167.0721 179.9486)"
            class="st7 st3 st8 animated-text"
          >
            4.6
          </text>
          <text
            transform="matrix(1.0134 0 0 1 88.709 179.9486)"
            class="st7 st3 st8 animated-text"
          >
            4.6
          </text>
          <text
            transform="matrix(1.0134 0 0 1 223.1465 202.8314)"
            class="st2 st3 st4"
          >
            {lang === "it" ? (
              <>
                <tspan x="8" y="0" class="st2 st3 st4">
                  Trasporto via{" "}
                </tspan>
                <tspan x="6.4" y="8.4" class="st2 st3 st4">
                  mare agli USA
                </tspan>
              </>
            ) : lang === "fr" ? (
              <>
                <tspan x="0" y="0" class="st2 st3 st4">
                  Fret maritime Vers{" "}
                </tspan>
                <tspan x="7.4" y="8.4" class="st2 st3 st4">
                  les États-Unis
                </tspan>
              </>
            ) : (
              t("Sea freight to US", lang)
            )}
          </text>
          <text
            transform="matrix(1.0134 0 0 1 142.0669 202.8314)"
            class="st2 st3 st4"
          >
            {lang === "it" ? (
              <>
                <tspan x="9" y="0" class="st2 st3 st4">
                  Trasporto aereo{" "}
                </tspan>
                <tspan x="10.9" y="8.4" class="st2 st3 st4">
                  per New York
                </tspan>
              </>
            ) : (
              t("Air freight New York", lang)
            )}
          </text>
          <text
            transform="matrix(1.0134 0 0 1 192.8032 229.4198)"
            class="st2 st3 st4"
          >
            {t("Freight", lang)}
          </text>
          <text
            transform="matrix(1.0134 0 0 1 132.2031 229.3197)"
            class="st6 st3 st4"
          >
            {t("Production", lang)}
          </text>
          <text
            transform="matrix(1.0134 0 0 1 65.8423 202.8314)"
            class="st2 st3 st4"
          >
            {lang === "it" ? (
              <>
                <tspan x="7" y="0" class="st2 st3 st4">
                  Trasporto aereo{" "}
                </tspan>
                <tspan x="10.8" y="8.4" class="st2 st3 st4">
                  per Shanghai
                </tspan>
              </>
            ) : (
              t("Air freight Shanghai", lang)
            )}
          </text>
          <text
            transform="matrix(1.0134 0 0 1 168.2529 148.1911)"
            class="st7 st3 st8 animated-text"
          >
            7.1
          </text>
          <text
            transform="matrix(1.0134 0 0 1 87.0112 133.34)"
            class="st7 st3 st8 animated-text"
          >
            11.6
          </text>
          <text
            transform="matrix(1.0134 0 0 1 242.6064 158.4097)"
            class="st9 st3 st8 animated-text"
          >
            0.35
          </text>
          <g>
            <g>
              <rect x="121.5" y="223.6" class="st6" width="6.7" height="6.7" />
            </g>
            <g>
              <rect x="182.1" y="223.6" class="st5" width="6.7" height="6.7" />
            </g>
          </g>
          <g>
            <g>
              <path
                class="st5"
                d="M256.2,136.7l1.9-1.6h4.7l-1.2-2h-12.8v3.6H256.2L256.2,136.7z"
              />
              <rect x="253.4" y="128.9" class="st5" width="3.7" height="3.1" />
              <path
                class="st5"
                d="M247.4,136.7l-0.6-5.2h-1.5v-1.3h-1.6v1.3H242v5.2H247.4z"
              />
              <polygon
                class="st5"
                points="258.6,136.1 256.8,137.7 239.6,137.7 240.8,141.4 241.4,143.1 263.6,143.1 265.8,136.1 				"
              />
            </g>
          </g>
          <g>
            <path
              class="st5"
              d="M165.8,107.3h14.6l0.8,1.8h4l-2-4.5l2-4.5h-4l-0.8,1.8h-14.6c-1.5,0-2.7,1.2-2.7,2.6
				C163,106.1,164.2,107.3,165.8,107.3L165.8,107.3z"
            />
            <path class="st5" d="M177.9,95.2h-3.6l-4.8,5.6h6.2L177.9,95.2z" />
            <path class="st5" d="M175.8,108.4h-6.2l4.8,5.6h3.6L175.8,108.4z" />
          </g>
          <g>
            <path
              class="st5"
              d="M87.6,81.4h14.6l0.8,1.8h4l-2-4.5l2-4.5h-4l-0.8,1.8H87.6c-1.5,0-2.7,1.2-2.7,2.6
				C84.9,80.2,86.1,81.4,87.6,81.4L87.6,81.4z"
            />
            <path class="st5" d="M99.8,69.3h-3.6l-4.8,5.6h6.2L99.8,69.3z" />
            <path class="st5" d="M97.6,82.5h-6.2l4.8,5.6h3.6L97.6,82.5z" />
          </g>
          <g>
            <text
              transform="matrix(1 0 0 1 69.1542 17.623)"
              class="st10 st3 st8"
            >
              CO
            </text>
            <text
              transform="matrix(1 0 0 1 84.6738 20.9535)"
              class="st10 st3 st11"
            >
              2
            </text>
            <text
              transform="matrix(1 0 0 1 87.9682 17.623)"
              class="st10 st3 st8"
            >
              {t("-emissions per transport type/distance:", lang)}
            </text>
          </g>
          <text
            transform="matrix(1 0 0 1 21.3405 58.0081)"
            class="st10 st3 st4"
          >
            {t("kg CO", lang)}
          </text>
          <text
            transform="matrix(1 0 0 1 41.6753 60.3392)"
            class="st10 st3 st12"
          >
            2
          </text>
          <text
            transform="matrix(1 0 0 1 43.9811 58.0081)"
            class="st10 st3 st4"
          >
            {t("per", lang)}
          </text>
          <text
            transform="matrix(1 0 0 1 21.3405 67.6346)"
            class="st10 st3 st4"
          >
            {t("kg HOG", lang)}
          </text>
        </g>
      </switch>
    </svg>
  )
}

export default Diagram3
